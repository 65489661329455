import './Navbar.css';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import yncn_logo from '../assets/yncn-logo.png';
import { NavLink } from 'react-router-dom';
import React from "react";


export default function NavbarYNCN() {
  return (
    <>
      <Navbar className="nav-header narbar-expand-xl" collapseOnSelect expand="lg" bg="white" variant="light">

        <div className="navbar-header">

          <div className="navbar-image-container">
            <NavLink to="/">
              <img className="navbar-image" src={yncn_logo} alt="yncn logo" />
            </NavLink>
          </div>
        </div>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className="justify-content-end" style={{ width: "100%" }}>

            <ul className="nav navbar-nav">

              <li>
                <a href="/partners" id="partners">Our Partners</a>
              </li>

              <NavDropdown title="Events" id="dropdown-text">
                <NavDropdown.Item href="/events" >Event Calendar</NavDropdown.Item>
                <NavDropdown.Item href="https://forms.yourenext.ca">Event Signup</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Career Fair" id="dropdown-text">
                <NavDropdown.Item href="/cf/about" disabled >About the Fair</NavDropdown.Item>
                {/* <NavDropdown.Item href="/cf/summit">Career Fair Summit</NavDropdown.Item> */}
                <NavDropdown.Item href="/cf/companies">Company List</NavDropdown.Item>
                <NavDropdown.Item href="https://maps.yourenext.ca" disabled>Venue Map</NavDropdown.Item>
              </NavDropdown>


              <li>
                <NavLink to="/resources" activeStyle={{ background: "#FAE5B4" }}  id="view_all">Resources</NavLink>
              </li>

              <NavDropdown title="About us" id="dropdown-text">
                <NavDropdown.Item href="/about">Who We Are</NavDropdown.Item>
                <NavDropdown.Item href="/team">Our Team</NavDropdown.Item>
              </NavDropdown>

              <li>
                <NavLink to="/contact" activeStyle={{ background: "#FAE5B4" }} id="contact">Contact Us</NavLink>
               </li>

              <li>
                <NavLink to="/employers" activeStyle={{ background:"#FAE5B4"}} id="employers-title">I'm an Employer</NavLink>
              </li>
            </ul>

          </Nav>
        </Navbar.Collapse>

      </Navbar>




    </>
  )

}
